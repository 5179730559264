import React from 'react'

import NextLink from 'next/link'

import { Button, Grid } from '@material-ui/core'
import StepImage from '../components/ui/StepImage'
import StepTitle from '../components/ui/StepTitle'
import StepText from '../components/ui/StepText'
import useTranslate from '../hooks/useTranslate'


const Page404: React.FC = () => {
  const { t } = useTranslate()

  return (
    <Grid container alignItems='center' spacing={2}>
      <Grid item xs={12} sm={5}>
        <StepImage type='duck' />
      </Grid>
      <Grid item xs={12} sm={7}>
        <StepTitle>404</StepTitle>
        <StepText>{t('404_text')}</StepText>
        <div>
          <NextLink href='/'>
            <Button
              variant='contained'
              color='primary'
            >
              {t('404_btn')}
            </Button>
          </NextLink>
        </div>
      </Grid>
    </Grid>
  )
}

export default Page404
